import { loadProgressPiliers } from './fonctions';

$(document).ready(function () {
  localforage.config({
    name: 'Lékol',
    version: '3',
  });

  $.getJSON('data/data.json', function (dataResponse) {
    window.localforage.setItem('appData', dataResponse);
    window.data = dataResponse;
    loadProgress(window.data);
  }).fail(function () {
    window.localforage.getItem('appData').then(function (value) {
      window.data = value;
      loadProgress(window.data);
    });
  });

  // Chargement des données de progression(On vérifie si de nouvelles données
  // d'exercice ont été intégrées). Pas encore possible de vérifier si des données d'exo ont été supprimées
  function loadProgress(data) {
    window.localforage.getItem('progress', function (err, progressRecord) {
      progressRecord = progressRecord || { piliers: {} };

      $.each(data.piliers, function (p, pilier) {
        var pilier_id = pilier.identifiant;

        if (!progressRecord.piliers.hasOwnProperty(pilier_id)) {
          progressRecord.piliers[pilier_id] = { fini: 0, entrees: {} };
        }

        $.each(data.piliers[p].entrees, function (e, entree) {
          var entree_id = entree.identifiant;

          if (
            !progressRecord.piliers[pilier_id].entrees.hasOwnProperty(entree_id)
          ) {
            progressRecord.piliers[pilier_id].entrees[entree_id] = {
              fini: 0,
              modules: {},
            };
          }

          $.each(data.piliers[p].entrees[e].modules, function (m, module) {
            var module_id = module.identifiant;

            if (
              !progressRecord.piliers[pilier_id].entrees[
                entree_id
              ].modules.hasOwnProperty(module_id)
            ) {
              progressRecord.piliers[pilier_id].entrees[entree_id].modules[
                module_id
              ] = { fini: 0 };
            }
          });
        });
      });

      window.progress = progressRecord;
      window.localforage.setItem('progress', progress);
      loadProgressPiliers();
    });
  }
});
