export function setupScreen() {
  window.localforage
    .getItem('nom')
    .then(function (nom) {
      if (!nom) {
        // Pas de nom enregistré
        showScreen($('.homescreen'));
      } else {
        // Un nom enregistré
        showScreen($('.menu'));
      }
    })
    .catch(function (err) {
      showScreen($('.homescreen'));
    });
}

//  ======================================
//  ||      CHARGEMENT DES ENTRÉES      ||
//  ======================================

export function loadEntrees() {
  var newEntrees = [];

  for (var i = 0; i < window.objPilier.entrees.length; i++) {
    var newEntree = $('.module').last().clone(true, true).removeClass('locked');
    newEntree.find('p').html(window.objPilier.entrees[i].titre);
    if (i > 0 && window.progPilier.entrees[i].fini == 0) {
      // Activation des modules propreté et BTP
      if (
        window.objPilier.identifiant == 3 &&
        window.objPilier.entrees[i].identifiant == 2
      ) {
        newEntree.removeClass('locked');
      } else {
        newEntree.addClass('locked');
      }
    }
    newEntrees.push(newEntree);
  }

  $('.modulelist').empty();
  $('.modulelist').append(newEntrees);
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||  CHARGEMENT DU PROGRESS ENTRÉES  ||
//  ======================================

export function loadProgressEntrees() {
  var i = 0;
  $.each($('.module'), function (ex, exo) {
    i++;
    var myEntree = window.progPilier.entrees[i];
    var totalModules = _.size(myEntree.modules);
    var sumModules = 0;
    $.each(myEntree.modules, function (m, module) {
      sumModules += module.fini;
    });

    var entreeProgress = (sumModules / totalModules) * 100;
    var $progress = $('.module')
      .eq(i - 1)
      .find('progress');
    $progress.attr('value', entreeProgress);
  });
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||      CHARGEMENT DES MODULES      ||
//  ======================================

export function loadModules() {
  var $titreEntree = $('.titre-entree');
  $titreEntree.html(window.objEntree.titre);

  var newModules = [],
    unlocked = false;

  for (var i = 0; i < window.objEntree.modules.length; i++) {
    var newModule = $('.exo')
      .last()
      .clone(true, true)
      .removeClass('fini locked');
    newModule.find('p').text(window.objEntree.modules[i].identifiant);
    if (window.progEntree.modules[i + 1].fini == 1) {
      newModule.addClass('fini');
    } else if (unlocked) {
      newModule.addClass('locked');
    } else {
      unlocked = true;
    }
    newModules.push(newModule);
  }

  $('.exolist').empty();
  $('.exolist').append(newModules);
}

/*
// Progress passé directement dans loadModules();
//
// -----------------------------------------------------------------------------
//  ======================================
//  ||  CHARGEMENT DU PROGRESS MODULES  ||
//  ======================================

function loadProgressModules() {
  var i = 0;

  $.each($( '.exo' ), function(e, exo) {
    i++;
    if (window.progEntree.modules[ i ].fini == 1) {
      $( '.exo' ).eq( i - 1 ).addClass( 'fini' );
    }
  })
}
*/

// -----------------------------------------------------------------------------
//  ======================================
//  ||     CHECK UNLOCK DES PILIERS     ||
//  ======================================

export function loadProgressPiliers() {
  var i = 0;
  for (var i = 1; i <= 4; i++) {
    $('.pilier')
      .eq(i)
      [progress.piliers[i].fini == 1 ? 'removeClass' : 'addClass']('locked');
  }
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||     FONCTION TRANSI ECRANS       ||
//  ======================================

export function showScreen($toShow, direction = 'right') {
  // $toShow est l'écran à montrer à l'utilisateur, $toHide est celui à masquer.
  // $toShow est passé à la fonction, $toHide est identifié comme l'écran
  // actuellement actif.

  // console.log( $toShow, direction );

  var $toHide = $('.active');
  var $topNav = $('.topnav');
  var $progress = $('.progress-top');

  //Si la topnav a besoin d'être animée, on l'anime. Si aucun changement
  //d'état, on ne l'anime pas.

  var showTop =
    $toShow.hasClass('show_top_nav') && !$toHide.hasClass('show_top_nav');
  var showProgress =
    $toShow.hasClass('show_progress') && !$toHide.hasClass('show_progress');
  var hideTop =
    !$toShow.hasClass('show_top_nav') &&
    ($toHide.length === 0 || $toHide.hasClass('show_top_nav'));
  var hideProgress =
    !$toShow.hasClass('show_progress') &&
    ($toHide.length === 0 || $toHide.hasClass('show_progress'));

  var directionLeft = direction == 'left';

  // Si l'animation doit montrer l'écran allant vers l'arrière (Le nouvel écran)
  // vient de la gauche.

  $('.addHidden')
    .addClass('hidden')
    .removeClass('left_show right_show right_hide left_hide addHidden');

  if (showTop) {
    $topNav
      .removeClass('left_show right_show right_hide left_hide hidden')
      .addClass(directionLeft ? 'left_show' : 'right_show');
  }
  if (hideTop) {
    $topNav
      .removeClass('left_show right_show right_hide left_hide active')
      .addClass('addHidden')
      .addClass(directionLeft ? 'left_hide' : 'right_hide');
  }
  if (showProgress) {
    $progress
      .removeClass('left_show right_show right_hide left_hide hidden')
      .addClass(directionLeft ? 'left_show' : 'right_show');
  }
  if (hideProgress) {
    $progress
      .removeClass('left_show right_show right_hide left_hide active')
      .addClass('addHidden')
      .addClass(directionLeft ? 'left_hide' : 'right_hide');
  }

  $toShow.removeClass('hidden');
  $toShow
    .removeClass('left_show right_show right_hide left_hide hidden')
    .addClass('active')
    .addClass(directionLeft ? 'left_show' : 'right_show');

  $toHide
    .removeClass('left_show right_show right_hide left_hide active')
    .addClass((directionLeft ? 'left_hide' : 'right_hide') + ' addHidden');

  if ($toShow.hasClass('menu-modules')) {
    var $exolist = $toShow.find('.exolist'),
      $lastunlocked = $exolist.find('.exo:not(.locked)').last();
    // Il est essentiel pour le calcul de la position du dernier élément de remettre le scroll du parent à 0
    $exolist.scrollLeft(0);
    $exolist.scrollLeft($lastunlocked.position().left);
    if (typeof window.objEntree.son !== 'undefined') {
      loadAudio(window.objEntree.son);
    }
  }
}

// -----------------------------------------------------------------------------
// Animation pour dévoiler le pilier sélectionné
// 1. Lis l’audio du nom du pilier
// 2. Masque l’écran avec un fade après la lecture, ou après 2s si l’audio n’est pas chargé
export function showPilier(pilier) {
  window.audiocontainer.pause();
  window.audiocontainer.currentTime = 0;
  window.audiocontainer.src = window.objPilier.son;
  window.audiocontainer.play();
  var $loaderPilier = $('.loader-pilier');
  $loaderPilier.removeClass('pilier1 pilier2 pilier3 pilier4');
  $loaderPilier.addClass(pilier);
  $loaderPilier.show();
  $(window.audiocontainer).one('ended.loader error.loader', function (e) {
    $loaderPilier.delay(e.type == 'error' ? 2000 : 0).fadeOut(350);
    $(this).off('.loader');
  });
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||       CHARGEMENT DES EXOS         ||
//  ======================================

export function loadExercice() {
  // Chargement des exercices
  if (window.exoEnCours == null) {
    // Suppression des anciens écrans, créés dans les précédents modules
    $('.copy').remove();
    // Nouveau module, lancement du premier exercice
    window.exoEnCours = 0;
    window.ecranEnCours = 0;
    window.nbEcransDone = 0;
    window.nbEcransDoneExo = 0;
    window.combo = 0;
    window.objExo =
      data.piliers[window.index_pilier].entrees[window.index_entree].modules[
        window.index_module
      ].exercices[window.exoEnCours];

    window.lengthModule = 0;

    $.each(
      data.piliers[window.index_pilier].entrees[window.index_entree].modules[
        window.index_module
      ].exercices,
      function (index, value) {
        window.lengthModule += value.ecrans.length;
      }
    );

    loadEcran();
  } else if (
    window.exoEnCours ==
    data.piliers[window.index_pilier].entrees[window.index_entree].modules[
      window.index_module
    ].exercices.length -
      1
  ) {
    // Fin des exercices et donc du module
    window.lengthModule = 0;
    window.exoEnCours = null;
    window.ecranEnCours = null;

    endModule();
  } else {
    // Exercice suivant
    window.exoEnCours++;
    window.nbEcransDoneExo = 0;
    window.nbFailsExo = 0;
    window.ecranEnCours = 0;
    window.combo = 0;
    window.objExo =
      data.piliers[window.index_pilier].entrees[window.index_entree].modules[
        window.index_module
      ].exercices[window.exoEnCours];
    loadEcran();
  }
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||     CHARGEMENT DES ECRANS        ||
//  ======================================

export function loadEcran() {
  // console.log( 'loadEcran' );

  loadProgressBar();
  if (window.ecranEnCours >= window.objExo.ecrans.length) {
    // Exercice terminé, retour à loadExercice() pour la suite
    loadExercice();
    return false;
  }

  var exo = window.objExo,
    ecran = exo.ecrans[window.ecranEnCours],
    typeEcran = exo.type,
    consigne = ecran.consigne,
    elemreponse = consigne[0].elemreponse[0],
    reponses = ecran.reponses,
    correcte = ecran.correcte,
    $ecran = $('.type' + typeEcran + '.template'),
    $fullscreenForm = $('.modal-input_form'),
    $fullscreenInput = $('.modal-input'),
    $textInput = $fullscreenInput.find('input');

  if (window.ecranEnCours > 0) {
    window.monEcran.off('.exercice');
    $fullscreenForm.off('.exercice');
    $fullscreenInput.off('.exercice');
  }
  $('.formtext').off('click.homescreen');
  window.failedScreen = false;
  window.exoConsigne = ecran.consigne[0].son;
  window.exoTimestamp = exo.timestamp;
  window.monEcran = $ecran
    .clone(true, true)
    .insertAfter($('.type' + typeEcran + '.mainexo').last());
  window.monEcran
    .addClass('ecran' + window.ecranEnCours + ' copy')
    .removeClass('template');

  // Lecture audio de la consigne au clic sur le bouton (?)
  window.monEcran.on('click.exercice', '.help', function (event) {
    playConsigne();
  });

  switch (typeEcran) {
    case 8:
      // Appuyer pour choisir une lettre parmi 4, avec visuel et écoute
      $.each(elemreponse, function (index, value) {
        window.monEcran
          .find('.consigne')
          .append(
            index == 'div'
              ? '<div class="' + value + ' cons2"></div>'
              : '<p class="cons1">' + value + '</p>'
          );
      });
      $.each(reponses, function (index, value) {
        window.monEcran.find('.lettre').eq(index).children().text(value);
      });

      window.monEcran.on('click.exercice', '.lettre', function (event) {
        var $this = $(this);
        if ($this.find('.item').text() == correcte) {
          ecranSuccess($this);
          $this.off(event);
        } else {
          ecranFail($this);
        }
      });

      break;

    case 9:
      $.each(reponses, function (index, value) {
        window.monEcran.find('.lettre').eq(index).children().text(value);
      });

      window.monEcran.on('click.exercice', '.lettre', function (event) {
        var $this = $(this);
        if ($this.find('.item').text() == correcte) {
          ecranSuccess($this);
          $this.off(event);
        } else {
          ecranFail($this);
        }
      });

      break;

    case 10:
      // Drag & Drop une lettre parmi 4, énoncé audio
      $.each(elemreponse, function (index, value) {
        window.monEcran
          .find('.consigne')
          .append(
            value == 'hearing'
              ? '<div class="' + value + ' cons1"></div>'
              : '<div class="dragdropzone cons6"><div class="dropzone dropreponse"></div></p>'
          );
      });
      $.each(reponses, function (index, value) {
        window.monEcran.find('.lettre').eq(index).children().text(value);
      });

      var droppable = new Droppable.default(
        document.querySelectorAll('.dropzone'),
        {
          appendTo: 'body',
          draggable: '.item',
          collidables: '.item',
          dropzone: '.dropzone',
        }
      );

      var droppableOrigin;

      droppable.on('drag:start', function (event) {
        $(event.originalSource).removeClass('anim');
        droppableOrigin = event.originalSource.parentNode;
      });

      droppable.on('drag:stop', function (event) {
        setTimeout(function () {
          if (
            window.monEcran
              .find('.dropreponse')
              .hasClass('draggable-dropzone--occupied')
          ) {
            window.monEcran
              .find('.consigne')
              .find('.dragdropzone')
              .removeClass('anim');
            var $item = window.monEcran.find('.dropreponse').find('.item');
            if ($item.text() == correcte) {
              droppable.destroy();
              ecranSuccess(
                window.monEcran.find('.consigne').find('.dragdropzone')
              );
            } else {
              setTimeout(function () {
                window.monEcran
                  .find('.dropreponse')
                  .removeClass('draggable-dropzone--occupied');
                ecranFail(
                  window.monEcran.find('.consigne').find('.dragdropzone')
                );
                $item.detach();
                $item.appendTo(droppableOrigin);
              }, 1200);
            }
          }
        }, 100);
      });

      break;

    case 11:
      // Ecrire une lettre au clavier, avec lettre en exemple
      window.monEcran
        .find('.consigneonly')
        .prepend('  <p class="cons1">' + elemreponse.p + '</p>');

      var $textRegion = window.monEcran.find('.exotext');

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      window.monEcran.on('click.exercice', '.exotext', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', correcte.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur();
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur();
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this),
          output = $textInput.val().toLowerCase(),
          textRegion = $textRegion.text().toLowerCase();

        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $textRegion.text(output);
        if (output == correcte.toLowerCase()) {
          // Bonne réponse
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case '11a':
      window.monEcran
        .find('.consigneonly')
        .prepend(
          '<p class="cons1">' +
            elemreponse.p +
            '</p><div class="hearing cons2"></div>'
        );

      var $textRegion = window.monEcran.find('.exotext');

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      window.monEcran.on('click.exercice', '.exotext', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', correcte.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur();
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur();
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this),
          output = $textInput.val().toLowerCase(),
          textRegion = $textRegion.text().toLowerCase();

        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $textRegion.text(output);
        if (output == correcte.toLowerCase()) {
          // Bonne réponse
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case 12:
      var $textRegion = window.monEcran.find('.exotext');

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      window.monEcran.on('click.exercice', '.exotext', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', correcte.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur(); //trigger( 'focusout', [ 'submit' ] );
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur(); //trigger( 'focusout', [ 'click' ] );
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this);
        if (isLowercase(correcte[0])) {
          var output = $this.val().toLowerCase(),
            textRegion = $this.text().toLowerCase();
        } else {
          var output = $this.val().toUpperCase(),
            textRegion = $this.text().toUpperCase();
        }

        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $textRegion.text(output);
        if (output == correcte) {
          // Bonne réponse
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case '12a':
      var $textRegion = window.monEcran.find('.exotext');

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      window.monEcran.on('click.exercice', '.exotext', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', correcte.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur(); //trigger( 'focusout', [ 'submit' ] );
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur(); //trigger( 'focusout', [ 'click' ] );
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this);
        if (isLowercase(correcte[0])) {
          var output = $this.val().toLowerCase(),
            textRegion = $this.text().toLowerCase();
        } else {
          var output = $this.val().toUpperCase(),
            textRegion = $this.text().toUpperCase();
        }

        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $textRegion.text(output);
        if (output == correcte) {
          // Bonne réponse
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case 13:
      var $imgHearing = window.monEcran.find('.image-hearing');
      $.each(reponses, function (index, value) {
        $imgHearing
          .eq(index)
          .find('.image-container')
          .css('background-image', 'url(' + value.img + ')');
        $imgHearing
          .eq(index)
          .find('.hearing.little')
          .attr('data-son', value.son);
      });

      window.monEcran.on('click.exercice', '.image-container', function () {
        var $this = $(this);
        var imgIndex = $imgHearing.index($this.parent());
        if (reponses[imgIndex].label == correcte) {
          ecranSuccess($this);
        } else {
          ecranFail($this);
        }
      });

      break;

    case 14:
      var $imgHearing = window.monEcran.find('.image-hearing');
      $imgHearing
        .find('.image-container')
        .css('background-image', 'url(' + consigne[1].img + ')');
      $imgHearing.find('.hearing.little').attr('data-son', consigne[1].son);

      $.each(elemreponse, function (index, value) {
        window.monEcran
          .find('.motlettre')
          .find('p:not(.lettre)')
          .append(index == 'span' ? '<span>&nbsp</span>' : value);
      });

      if (exo.syllabe) {
        window.monEcran.find('span').addClass('span-syllabe');
      }

      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.clicklettre')
          .append('<p class="lettre cons3">' + value + '</p>');
      });

      window.monEcran.on('click.exercice', '.lettre', function () {
        var $this = $(this);
        window.monEcran.find('span').text($this.text());
        if ($this.text() == correcte) {
          ecranSuccess(window.monEcran.find('span'));
          $this.off(event);
        } else {
          ecranFail(window.monEcran.find('span'));
        }
      });

      break;

    case '14a':
      var $imgHearing = window.monEcran.find('.image-hearing');
      $imgHearing
        .find('.image-container')
        .css('background-image', 'url(' + consigne[1].img + ')');
      $imgHearing.find('.hearing.little').attr('data-son', consigne[1].son);

      $.each(elemreponse, function (index, value) {
        window.monEcran
          .find('.motlettre')
          .find('p')
          .append(index == 'span' ? '<span>' + value + '</span>' : value);
      });

      $.each(reponses, function (index, value) {
        window.monEcran.find('.lettre').eq(index).text(value);
      });

      window.monEcran.on('click.exercice', '.lettre', function () {
        var $this = $(this);
        if ($this.text() == correcte) {
          ecranSuccess(window.monEcran.find('span'));
          $this.off(event);
        } else {
          ecranFail(
            window.monEcran.find('span', function () {
              window.monEcran.find('span').text(correcte);
            })
          );
        }
      });

      break;

    case 15:
      var $textRegion = window.monEcran.find('.exotext');

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      var $imgHearing = window.monEcran.find('.image-hearing');
      $imgHearing
        .find('.image-container')
        .css('background-image', 'url(' + consigne[1].img + ')');
      $imgHearing.find('.hearing.little').attr('data-son', consigne[1].son);

      $.each(elemreponse, function (index, value) {
        window.monEcran
          .find('.motlettre')
          .find('p')
          .append(
            index == 'span' ? '<span class="cons3">&nbsp;</span>' : value
          );
      });

      if (exo.syllabe) {
        window.monEcran.find('span').addClass('span-syllabe');
      }

      var $textRegion = window.monEcran.find('span');

      window.monEcran.on('click.exercice', 'span', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', correcte.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur();
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur();
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this),
          output = $textInput.val().toLowerCase(),
          textRegion = $textRegion.text().toLowerCase();

        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $textRegion.text(output);
        if (output == correcte.toLowerCase()) {
          // Bonne réponse
          $textInput.attr('maxlength', 524288);
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
        } else {
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case 16:
      $.each(reponses, function (index, value) {
        window.monEcran.find('.lettre').eq(index).children().text(value);
      });
      window.monEcran.off('.exercice', '.hearing');
      window.monEcran.on('click.exercice', '.hearing', function (event) {
        var enfant = 0;
        var text = [];
        window.monEcran.find('.dropreponse').each(function (index, value) {
          if ($(this).children().length > 0) {
            text.push($(this).find('p').text());
            enfant++;
          } else {
            return;
          }
        });
        if (enfant == 2) {
          var textStr = text.join('');
          if (correcte[textStr] === undefined) {
            setTimeout(function () {
              ecranFail(window.monEcran.find('.dragdropreponse'));
            }, 500);
          } else {
            droppable.destroy();
            loadAudio(correcte[textStr], function () {
              ecranSuccess(window.monEcran.find('.dragdropreponse'));
            });
          }
        }
      });

      var droppable = new Droppable.default(
        document.querySelectorAll('.dropzone'),
        {
          appendTo: 'body',
          draggable: '.item',
          collidables: '.item',
          dropzone: '.dropzone',
        }
      );

      droppable.on('drag:start', function (event) {
        $(event.originalSource).removeClass('anim');
      });

      droppable.on('drag:stop', function (event) {
        $(event.originalSource).removeClass('anim');
      });

      break;

    case '16a':
      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.lettre')
          .eq(index)
          .attr('drop-id', index)
          .children()
          .text(value)
          .attr('drop-id', index);
      });

      window.monEcran.find('.text').text(elemreponse.text);

      var droppable = new Droppable.default(
        document.querySelectorAll('.dropzone'),
        {
          appendTo: 'body',
          draggable: '.item',
          collidables: '.item',
          dropzone: '.dropzone',
        }
      );

      droppable.on('drag:start', function (event) {
        $(event.originalSource).removeClass('anim');
      });

      droppable.on('drag:stop', function (event) {
        $(event.originalSource).removeClass('anim');
        setTimeout(function () {
          var enfant = 0,
            text = [];
          window.monEcran.find('.dropreponse').each(function (index, value) {
            if ($(this).children().length > 0) {
              text.push($(this).find('p').text());
              enfant++;
            } else {
            }
          });
          if (enfant == 2) {
            var textStr = text.join('');
            if (correcte == textStr) {
              ecranSuccess(window.monEcran.find('.dragdropreponse'));
            } else {
              setTimeout(function () {
                ecranFail(window.monEcran.find('.dragdropreponse'));
                setTimeout(function () {
                  window.monEcran
                    .find('.dropreponse')
                    .removeClass('draggable-dropzone--occupied');
                  window.monEcran
                    .find('.dropreponse')
                    .each(function (index, value) {
                      var $this = $(this).find('p');
                      $this.appendTo(
                        window.monEcran.find(
                          '.lettre[drop-id=' +
                            $(this).find('p').attr('drop-id') +
                            ']'
                        )
                      );
                    });
                }, 500);
              }, 350);
            }
          }
        }, 100);
      });

      break;

    case '16b':
      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.lettre')
          .eq(index)
          .attr('drop-id', index)
          .children()
          .text(value)
          .attr('drop-id', index);
      });

      var droppable = new Droppable.default(
        document.querySelectorAll('.dropzone'),
        {
          appendTo: 'body',
          draggable: '.item',
          collidables: '.item',
          dropzone: '.dropzone',
        }
      );

      droppable.on('drag:start', function (event) {
        $(event.originalSource).removeClass('anim');
      });

      droppable.on('drag:stop', function (event) {
        $(event.originalSource).removeClass('anim');
        setTimeout(function () {
          var enfant = 0,
            text = [];
          window.monEcran.find('.dropreponse').each(function (index, value) {
            if ($(this).children().length > 0) {
              text.push($(this).find('p').text());
              enfant++;
            } else {
            }
          });
          if (enfant == 2) {
            var textStr = text.join('');
            if (correcte == textStr) {
              ecranSuccess(window.monEcran.find('.dragdropreponse'));
            } else {
              setTimeout(function () {
                ecranFail(window.monEcran.find('.dragdropreponse'));
                setTimeout(function () {
                  window.monEcran
                    .find('.dropreponse')
                    .removeClass('draggable-dropzone--occupied');
                  window.monEcran
                    .find('.dropreponse')
                    .each(function (index, value) {
                      var $this = $(this).find('p');
                      $this.appendTo(
                        window.monEcran.find(
                          '.lettre[drop-id=' +
                            $(this).find('p').attr('drop-id') +
                            ']'
                        )
                      );
                    });
                }, 500);
              }, 350);
            }
          }
        }, 100);
      });

      break;

    case 17:
      window.monEcran
        .find('.consigne')
        .append('<p class="cons1">' + reponses[0] + '</p>');
      var $text = window.monEcran.find('.consigne').find('p'),
        $bouton = window.monEcran.find('.next'),
        clicked;
      $text.attr('data-text', reponses[0]);

      window.monEcran.on('click.exercice', '.hearing', function () {
        loadAudio(consigne[0].audio, function () {
          //$text.addClass( 'done' ).removeClass( 'karaoke' );
          $bouton.removeClass('hidden');
          window.monEcran.one(
            'click.exercice',
            '.next:not(.hidden)',
            function () {
              if (!clicked) {
                clicked = true;
                ecranSuccess();
              }
            }
          );
        });
        $text.removeClass('karaoke');
        setTimeout(function () {
          $text.addClass('karaoke');
        }, 10);
      });

      break;

    case 18:
      var $imgHearing = window.monEcran.find('.image-hearing');
      $.each(reponses, function (index, value) {
        $imgHearing
          .eq(index)
          .find('.image-container')
          .css('background-image', 'url(' + value.img + ')');
        $imgHearing
          .eq(index)
          .find('.hearing.little')
          .attr('data-son', value.son);
      });

      window.monEcran.find('p').text(elemreponse);

      $imgHearing.on('click.exercice', '.image-container', function () {
        var $this = $(this);
        var imgIndex = $imgHearing.index($this.parent());
        if (reponses[imgIndex].label == correcte) {
          ecranSuccess($this);
        } else {
          ecranFail($this);
        }
      });

      break;

    case 19:
      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.reponses')
          .append(
            index == 'div'
              ? '<div class="' + value + ' cons2"></div>'
              : '<p class="cons2">' + value + '</p>'
          );
      });

      elemreponse = consigne[0].elemreponse;

      window.monEcran.find('.mot').text(elemreponse);
      window.monEcran
        .find('.reponses')
        .on('click.exercice', 'p', function (event) {
          var $this = $(this);
          if ($this.text() == correcte) {
            ecranSuccess($this);
            $this.off(event);
          } else {
            ecranFail($this);
          }
        });
      break;

    case 20:
      var answer;

      $.each(reponses, function (index, value) {
        var valueText;
        var test;
        var $p = $('<p>').addClass('cons2');
        $p.attr('data-index', index);
        window.monEcran.find('.reponses').append($p);
        if (index == 'nom') {
          window.localforage.getItem('nom').then(function (value) {
            answer = value;
            window.monEcran
              .find('.reponses')
              .find('p[data-index="nom"]')
              .text(value);
          });
        } else if (index == 'prenom') {
          window.localforage.getItem('prenom').then(function (value) {
            answer = value;
            window.monEcran
              .find('.reponses')
              .find('p[data-index="prenom"]')
              .text(value);
          });
        } else {
          window.monEcran.find('.reponses').find('p:last-child').text(value);
        }
      });

      window.monEcran
        .find('.reponses')
        .on('click.exercice', 'p', function (event) {
          var $this = $(this);
          if (!answer) {
            answer = correcte;
          }
          if ($this.text() == answer) {
            ecranSuccess($this);
            $this.off(event);
          } else {
            ecranFail($this);
          }
        });
      break;

    case 21:
      var $textRegion = window.monEcran.find('.exotext');
      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }
      var personalData;
      if (correcte == 'prenom') {
        window.localforage.getItem('prenom').then(function (value) {
          personalData = value;
          addFieldType();
        });
      } else if (correcte == 'nom') {
        window.localforage.getItem('nom').then(function (value) {
          personalData = value;
          addFieldType();
        });
      }

      function addFieldType() {
        if (elemreponse.type == 'cadre') {
          $textRegion.addClass('exotext-cadre');
        } else if (elemreponse.type == 'peigne') {
          $textRegion.addClass('exotext-peigne');
          for (var i = 0; i < personalData.length; i++) {
            $textRegion.append('<u>&nbsp;</u>');
          }
        } else if (elemreponse.type == 'cases') {
          $textRegion.addClass('exotext-cases');
          for (var i = 0; i < personalData.length; i++) {
            $textRegion.append('<i>&nbsp;</i>');
          }
        }
      }

      if (isLowercase(correcte[0])) {
        $textInput.attr('autocapitalize', 'none');
        $textInput.css('text-transform', 'lowercase');
        $textRegion.css('text-transform', 'lowercase');
      } else {
        $textInput.attr('autocapitalize', 'characters');
        $textInput.css('text-transform', 'uppercase');
        $textRegion.css('text-transform', 'uppercase');
      }

      window.monEcran.on('click.exercice', '.exotext', function () {
        if (isLowercase(correcte[0])) {
          $textInput.attr('autocapitalize', 'none');
          $textInput.css('text-transform', 'lowercase');
          $textRegion.css('text-transform', 'lowercase');
        } else {
          $textInput.attr('autocapitalize', 'characters');
          $textInput.css('text-transform', 'uppercase');
          $textRegion.css('text-transform', 'uppercase');
        }
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.attr('maxlength', 25480);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur(); //trigger( 'focusout', [ 'submit' ] );
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur(); //trigger( 'focusout', [ 'click' ] );
      });

      $textInput.on('focusout.exercice', function () {
        var $this = $(this);

        var output = $this.val().toUpperCase(),
          textRegion = $this.text();

        $fullscreenInput.addClass('hidden');

        if (lastAnswer) {
          if (lastAnswer == output) {
            return false;
          }
        }
        // Si aucun output est donné, on ne fait rien
        if (output == '') {
          return false;
        }

        //Ecriture de la réponse donnée dans la case selon le type de réponse attendu
        if (elemreponse.type == 'cadre') {
          $textRegion.text(output);
        } else if (elemreponse.type == 'peigne') {
          $textRegion.empty();
          for (var i = 0; i < output.length; i++) {
            $textRegion.append('<u>' + output[i] + '</u>');
          }
        } else if (elemreponse.type == 'cases') {
          $textRegion.empty();
          for (var i = 0; i < output.length; i++) {
            $textRegion.append('<i>' + output[i] + '</i>');
          }
        }
        //Vérif bonne réponse
        if (output == personalData) {
          // Bonne réponse
          ecranSuccess($textRegion);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          var lastAnswer = output;
          // Mauvaise réponse
          ecranFail($textRegion);
        }
      });

      break;

    case 22:
      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.reponses')
          .append(
            index == 'div'
              ? '<div class="' + value + ' cons2"></div>'
              : '<p class="cons3">' + value + '</p>'
          );
      });

      elemreponse = consigne[0].elemreponse;

      window.monEcran.find('.mot').text(elemreponse);
      window.monEcran
        .find('.reponses')
        .on('click.exercice', 'p', function (event) {
          var $this = $(this);
          if ($this.text() == correcte) {
            ecranSuccess($this);
            $this.off(event);
          } else {
            ecranFail($this);
          }
        });
      break;

    case 23:
      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.forms')
          .append(
            '<div class="formtext"><p>' +
              value +
              '</p><div class="field cons' +
              (index + 2) +
              '"></div></div>'
          );
      });

      elemreponse = consigne[0].elemreponse;
      var $fields = window.monEcran.find('.field');

      if (elemreponse.type == 'cadre') {
        $fields.addClass('field-cadre');
      } else if (elemreponse.type == 'peigne') {
        $fields.addClass('field-peigne');
        for (var i = 0; i < 6; i++) {
          $fields.append('<u>&nbsp;</u>');
        }
      } else if (elemreponse.type == 'cases') {
        $fields.addClass('field-cases');
        for (var i = 0; i < 6; i++) {
          $fields.append('<i>&nbsp;</i>');
        }
      }

      window.monEcran.on('click.exercice', '.field', function () {
        var $this = $(this);
        var reponse = $this.parent().find('p').text();
        if (reponse == correcte) {
          // Bonne réponse
          ecranSuccess($this);
          $textInput.off('focusout.exercice');
        } else {
          // Mauvaise réponse
          ecranFail($this);
        }
      });

      break;

    case 24:
      var answer;
      elemreponse = consigne[0].elemreponse;

      if (elemreponse.type == 'nom') {
        window.localforage.getItem('nom').then(function (value) {
          answer = value;
        });
      } else if (elemreponse.type == 'prenom') {
        window.localforage.getItem('prenom').then(function (value) {
          answer = value;
        });
      }

      $.each(reponses, function (index, value) {
        window.monEcran
          .find('.forms')
          .append(
            '<div class="formtext"><p class="cons1">' +
              value +
              '</p><div class="field field-cadre cons2"></div></div>'
          );
      });
      var $formField = window.monEcran.find('.field');

      window.monEcran.on('click.exercice', '.formtext', function () {
        var $this = $(this);
        $fullscreenInput.removeClass('hidden');
        $textInput.css('text-transform', 'uppercase');
        $textInput.attr('autocapitalize', 'characters');
        $textInput.attr('maxlength', answer.length);
        $textInput.val('').focus();
      });

      $fullscreenForm.on('submit.exercice', function () {
        $textInput.blur();
        return false;
      });

      $fullscreenInput.on('click.exercice', function () {
        if ($textInput.is(event.target)) {
          return;
        }
        $textInput.blur();
      });

      $textInput.on('focusout.exercice', function () {
        $textInput.css('text-transform', 'none');
        $textInput.attr('autocapitalize', 'none');

        var $this = $(this),
          output = $textInput.val().toUpperCase(),
          textRegion = $formField.text();
        if (output == '') {
          return false;
        }
        $fullscreenInput.addClass('hidden');
        if (textRegion == output || output.length == 0) {
          return false;
        }
        $formField.text(output);
        if (output == answer.toUpperCase()) {
          // Bonne réponse
          ecranSuccess($formField);
          $textInput.off('focusout.exercice');
          $textInput.attr('maxlength', 524288);
        } else {
          // Mauvaise réponse
          ecranFail($formField);
        }
      });

      break;

    default:
  }

  window.monEcran
    .find('.hearing:not(.little)')
    .attr('data-son', consigne[0].audio);

  // Lecture audio au clic sur l’oreille
  window.monEcran.on('click.exercice', '.hearing[data-son]', function (event) {
    loadAudio($(this).attr('data-son'));
  });

  // -----------------------------------------------------------------------------
  showScreen(window.monEcran);
  if (window.ecranEnCours == 0) {
    showLoaderExo();
  } else if (window.ecranEnCours < 2) {
    playConsigne();
  }

  window.ecranEnCours++;
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||    LOADING PROGRESS BAR TOP      ||
//  ======================================

export function loadProgressBar() {
  var $progress = $('.progress-top').find('progress');
  var value = (window.nbEcransDoneExo / window.objExo.ecrans.length) * 100;
  $progress.attr('value', value);
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||         ECRAN REUSSI             ||
//  ======================================

export function ecranSuccess(element) {
  var directory;
  if (window.index_pilier == 0 || 3) {
    directory = 'lettresetsons';
  }
  if (window.index_pilier == 1) {
    directory = 'lecturesyllabique';
  }
  if (window.index_pilier == 2) {
    directory = 'vocpro';
  }
  if (element) {
    window.monEcran.off('.exercice');
    element.removeClass('anim');
    window.audiocontainer.removeAttribute('src');
    // window.audiocontainer.load();
    element.playKeyframe('borderSuccess .5s ease-in', function () {
      element.resetKeyframe();
      element.css('animation', '');
      window.erreurs = 0;
      window.nbEcransDone++;
      window.nbEcransDoneExo++;
      window.combo++;
      if (![17, 16].includes(window.objExo.type)) {
        if (window.combo == 1) {
          loadAudio('audio/' + directory + '/feedback/bravo.mp3', loadEcran, 0);
        }
        if (window.combo == 2) {
          loadAudio(
            'audio/' + directory + '/feedback/genial.mp3',
            loadEcran,
            0
          );
        }
        if (window.combo > 2) {
          loadAudio(
            'audio/' + directory + '/feedback/excellent.mp3',
            loadEcran,
            0
          );
        }
      } else {
        loadEcran();
      }
    });
  } else {
    window.erreurs = 0;
    window.nbEcransDone++;
    window.nbEcransDoneExo++;
    window.combo++;
    if (![17].includes(window.objExo.type)) {
      if (window.combo == 1) {
        loadAudio('audio/' + directory + '/feedback/bravo.mp3', loadEcran, 0);
      }
      if (window.combo == 2) {
        loadAudio('audio/' + directory + '/feedback/genial.mp3', loadEcran, 0);
      }
      if (window.combo > 2) {
        loadAudio(
          'audio/' + directory + '/feedback/excellent.mp3',
          loadEcran,
          0
        );
      }
    } else {
      loadEcran();
    }
  }

  // > Maël : je ne sais pas trop pourquoi il y a ça, mais ça fait déconner la navigation dès qu’on passe le premier écran
  // Le problème c’est que tu attaches un évènement tout le temps, pas seulement dans ton appel de fonction, donc il continue d’exister au-delà
  // Si tu veux désactiver le clic, il faudrait plutôt définir une variable, ou utiliser une classe CSS
  //
  // $( '.topnav_back' ).on( 'click', function() {
  //   return false;
  // } );
  // $( '.topnav_home' ).on( 'click', function() {
  //   return false;
  // } );
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||         ECRAN LOUPÉ              ||
//  ======================================

export function ecranFail(element, callback) {
  var directory;
  if (window.index_pilier == 0 || 3) {
    directory = 'lettresetsons';
  }
  if (window.index_pilier == 1) {
    directory = 'lecturesyllabique';
  }
  if (window.index_pilier == 2) {
    directory = 'vocpro';
  }

  window.combo = 0;
  element.removeClass('anim');
  element.playKeyframe('borderFail .5s ease-in', function () {
    element.resetKeyframe();
    element.css('animation', '');
    window.erreurs++;
    if (window.erreurs == 1) {
      if ([13, 18].includes(window.objExo.type)) {
        if (!window.failedScreen) {
          window.nbFails++;
          window.nbFailsExo++;
          window.failedScreen = true;
        }
      }
      loadAudio('audio/' + directory + '/feedback/essaieencore.mp3');
    }
    if (window.erreurs == 2) {
      loadAudio('audio/' + directory + '/feedback/reessaie.mp3');
      if (!window.failedScreen) {
        window.nbFails++;
        window.nbFailsExo++;
        window.failedScreen = true;
      }
    }
    if (window.erreurs > 2) {
      loadAudio('audio/' + directory + '/feedback/encoreunechance.mp3');
      window.monEcran.find('.icon-question').addClass('anim');
    }
    if ([11, '11a', 12, '12a', 14, 15, 21].includes(window.objExo.type)) {
      element.html('&nbsp;');
    } else if ([21].includes(window.objExo.type)) {
    }
    if (callback) {
      callback;
    }
  });
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||  FONCTION GESTION FIN DE MODULE  ||
//  ======================================

export function endModule() {
  var $ecran = $('.type25');
  var ecranDeFin = $ecran.clone(true, true).insertAfter($ecran);
  ecranDeFin.addClass('copy');
  var ratio = (window.nbEcransDone - window.nbFails) / window.nbEcransDone;
  var exit = $('.menu-modules');

  // Réussite du module
  if (ratio >= 0.7) {
    ecranDeFin.find('.center').addClass('fini_success');
    window.progModule.fini = 1;
    loadModules();
    // loadProgressModules(); > progress passé dans loadModules()

    // Compte le nombre de modules finis
    var sumModules = 0;
    $.each(window.progEntree.modules, function (m, module) {
      sumModules += module.fini;
    });

    // Tous les modules sont finis
    if (window.objEntree.modules.length == sumModules) {
      window.progEntree.fini = 1;
      loadEntrees();
      loadProgressEntrees();

      // Compte le nombre d’entrées finies
      var sumEntrees = 0;
      $.each(window.progPilier.entrees, function (e, entree) {
        sumEntrees += entree.fini;
      });

      // Toutes les entrées sont finies, on revient au menu principal
      if (window.objPilier.entrees.length == sumEntrees) {
        exit = $('.menu');
        window.progPilier.fini = 1;
        loadProgressPiliers();
      }
      // Il reste des entrées à faire, on revient à la liste des entrées
      else {
        exit = $('.menu-entrees');
      }
    }
  }

  // Échec du module
  else {
    ecranDeFin.find('.center').addClass('fini_failure');
  }

  window.localforage.setItem('progress', progress);
  showScreen(ecranDeFin);

  ecranDeFin.find('.fini_next').one('click.finmodule', function () {
    showScreen(exit, 'left');
  });
  ecranDeFin.find('.fini_home').one('click.finmodule', function () {
    showScreen($('.menu'), 'left');
  });
}

function isLowercase(char) {
  if (char == char.toLowerCase()) {
    return true;
  } else if (char == char.toUpperCase()) {
    return false;
  }
}

// -----------------------------------------------------------------------------
// Chargement du fichier audio, avec callback
// En cas d’erreur, on charge quand même le callback, sauf si le délai est inférieur à 0
export function loadAudio(src, callback, errorDelay = 2000) {
  $(window.audiocontainer).off();
  window.audiocontainer.src = src;
  window.audiocontainer.play();
  if (callback) {
    $(window.audiocontainer).one('ended error', function (e) {
      if (e.type === 'error') {
        if (errorDelay > 0) {
          setTimeout(callback, errorDelay);
        } else if (errorDelay === 0) {
          callback();
        }
        // On ne charge pas le callback si errorDelay est inférieur à 0
      } else {
        callback();
      }
    });
  }
}

// -----------------------------------------------------------------------------
//  ======================================
//  ||     AFFICHAGE DU LOADER EXO      ||
//  ======================================

export function showLoaderExo() {
  var $progress = $('.progress-top');
  var $loader = window.monEcran.find('.loader-exercice');

  $progress.fadeOut(350);

  $loader.html(
    '<p>' +
      data.piliers[window.index_pilier].entrees[window.index_entree].modules[
        window.index_module
      ].exercices[window.exoEnCours].titre +
      '</p>'
  );

  $loader.addClass('show-loader');

  loadAudio(window.objExo.son, function () {
    $progress.removeClass('right_show');
    $loader.fadeOut(350, function () {
      $loader.removeClass('show-loader');
    });
    $progress.fadeIn(350, function () {
      $progress.removeAttr('style');
      $progress.removeClass('hidden');
    });
    playConsigne();
  });
}

// -----------------------------------------------------------------------------
// Joue la consigne audio au début d'un écran ou au clic sur le bouton aide, et anime les éléments à l'écran

export function playConsigne() {
  $('.anim').removeClass('anim');
  loadAudio(window.exoConsigne);
  var triggered = [];
  for (var i = 0; i < window.exoTimestamp.length; i++) {
    triggered[i] = 0;
    $('anim').removeClass('anim');
  }
  $(window.audiocontainer).on('timeupdate', function () {
    var currentTime = Math.round(this.currentTime * 2) / 2;
    for (var i = 0; i < window.exoTimestamp.length; i++) {
      var u = i + 1;
      if (currentTime >= window.exoTimestamp[i] && triggered[i] == 0) {
        triggered[i] = 1;
        //playAnim($( '.active' ).find( '.cons'+u ))
        $('.active')
          .find('.cons' + u)
          .addClass('anim');
      }
    }
  });
}
// -----------------------------------------------------------------------------
export function showModalInstall(text) {
  var $modalInstall = $('.modal-install');
  $modalInstall.find('p').text(text);
  $modalInstall.removeClass('hidden');
  $modalInstall.on('click', function (event) {
    if ($modalInstall.is(event.target)) {
      $modalInstall.addClass('hidden');
    }
  });
}

//------------------------------------------------------------------------------
export function askReload() {
  if (window.reloadReady == true) {
    window.location.reload(true);
    window.location = location.href;
    location.assign(self.location.href);
    location.replace(self.location.href);
  }
}
// -----------------------------------------------------------------------------
export function promptUser() {
  if (window.deferredPrompt !== undefined) {
    // The user has had a postive interaction with our app and Chrome
    // has tried to prompt previously, so let's show the prompt.

    window.deferredPrompt.prompt();

    // Follow what the user has done with the prompt.
    window.deferredPrompt.userChoice.then(function (choiceResult) {
      if (choiceResult.outcome == 'dismissed') {
      } else {
      }

      // We no longer need the prompt.  Clear it up.
      window.deferredPrompt = null;
    });
  }
}
