window.ecranEnCours = null;
window.typeEcran = null;
window.nbEcransDone = 0;
window.nbEcransDoneExo = 0;
window.nbFails = 0;
window.nbFailsExo = 0;
window.erreurs = 0;
window.combo = 0;
window.audiocontainer = document.createElement('audio');
window.ua = detect.parse(navigator.userAgent);
window.lengthModule = 0;
window.exoEnCours = null;
window.updateFound = false;
window.loaderCount = 0;
window.vivusDestroyed = false;
window.reloadReady = false;
// data : Données d'exercice
// progress : Données de progression utilisateur
// window.index_pilier : Index du pilier sélectionné
// window.index_entree : Index de l'entrée sélectionnée
// window.index_module : Index du module sélectionné
// window.index_exercice : Index de l'exercice en cours
// window.objPilier : Objet regroupant les données d'exercice du pilier sélectionné
// window.progPilier : Objet regroupant les données utilisateur du pilier sélectionné
// window.objEntree : Objet regroupant les données d'exercice de l'entrée sélectionné
// window.progEntree : Objet regroupant les données utilisateur de l'entrée sélectionné
// window.objModule : Objet regroupant les données d'exercice du module sélectionné
// window.progModule : Objet regroupant les données utilisateur du module sélectionné
// window.lengthModule : Longueur du module
// window.exoEnCours = : Index de l'exo en cours
// window.monEcran : Objet JQuery de l'écran en cours
// window.objExo : Objet regroupant les données d'exercice de l'exercice en cours
// window.exoConsigne : Consigne de l'exercice en cours
// window.exoTimestamp : Timestamps des exercices en cours
// window.ecranEnCours : Index de l'écran en cours
// window.typeEcran : numéro (de 8 à 24 actuellement) de l'écran joué
// window.nbEcransDone : Compteur du nombre d'écrans terminés du module
// window.nbFails : Nombre d'écrans échoués
// window.erreurs : Nombre d'erreur pendant l'écran en cours
// window.combo : window.combo d'exercices réussis
// window.audiocontainer : Objet audio
// deferredPrompt : Objet événement de proposition d'installation de l'appli
// window.ua : Objet regroupant les infos de l'User Agent, organisés par detect.min.js
// updatefound : Est-ce qu'une update du SW a été trouvé dans les 3 dernières secondes ? Vrai si onupdatefound a été déclenché
