import './setup';
import './var';
import {
  loadProgressPiliers,
  setupScreen,
  loadEntrees,
  askReload,
  loadExercice,
  loadModules,
  loadProgressBar,
  loadProgressEntrees,
  playConsigne,
  promptUser,
  showModalInstall,
  showPilier,
  showScreen,
} from './fonctions';

$(document).ready(function () {
  //  ======================================
  //  ||        ANIM CHARGEMENT           ||
  //  ======================================

  if ($('#mySVG').length > 0) {
    var vivusEpar = new Vivus(
      'mySVG',
      {
        type: 'oneByOne',
        file: 'img/logo_eparpille.svg',
        animTimingFunction: Vivus.EASE,
        duration: '150',
      },
      function (myVivus) {
        if (!window.vivusDestroyed) {
          window.loaderCount++;
          setTimeout(function () {
            myVivus.play(myVivus.currentFrame === 0 ? 1 : -1);
          }, 400);
          if (window.loaderCount % 2 == 0) {
            if (window.updateFound) {
              askReload();
            } else {
              myVivus.destroy();
              $('#mySVG').remove();
              window.vivusDestroyed = true;

              var vivusLogo = new Vivus(
                'logoSVG',
                {
                  type: 'oneByOne',
                  file: 'img/logo_trace.svg',
                  animTimingFunction: Vivus.EASE,
                  duration: '150',
                },
                function (myVivus) {
                  $('.loading-screen')
                    .find('p')
                    .fadeIn(500, function () {
                      setTimeout(function () {
                        setupScreen();
                      }, 800);
                    });
                }
              );
            }
          }
        }
      }
    );
  }

  if ($('#logo').length > 0) {
    const myVivus = new Vivus('logo', {
      type: 'oneByOne',
      file: 'logo_trace.svg',
      animTimingFunction: Vivus.EASE,
      duration: '150',
    });
  }

  // setInterval(function () {
  //   console.log( myVivus.getStatus() );
  // }, 200);

  //  ======================================
  //  ||          HOME SCREEN             ||
  //  ======================================

  var $formulaire = $('#formulaire');
  var firstName = $('#first_name');
  var surName = $('#surname');
  var errorText = $('#formerror');

  function checkInput(element) {
    if (element.text() == null || element.text() == '') {
      element.addClass('invalidinput');
      errorText.html("Merci d'entrer un Prénom et un Nom");
      return true;
    } else {
      return false;
    }
  }

  // Désactiver le scroll et le rebond sur iOS

  // Permettre le scroll à la roulette dans le menu des modules
  // > Maël : attention, uniquement sur la valeur en Y, pas en X, mais même avec ça, ça marche pas super bien avec le touchpad
  $('.exolist').mousewheel(function (e) {
    if (e.deltaX === 0) {
      this.scrollLeft -= e.deltaY * 40;
    }
  });

  // -----------------------------------------------------------------------------
  // A l'envoi du formulaire, on vérifie que les informations ont été remplis,
  // puis on affiche le menu des piliers.

  $formulaire.on('submit', function () {
    var error1 = checkInput(surName);
    var error2 = checkInput(firstName);

    if (error1 || error2) {
      return false;
    } else {
      prenom = firstName.text().toUpperCase();
      nom = surName.text().toUpperCase();
      window.localforage.setItem('prenom', prenom);
      window.localforage.setItem('nom', nom);
      showScreen($('.menu'));
      return false;
    }
  });
  // -----------------------------------------------------------------------------
  // bouton retour. On revient à l'écran précédent, selon celui
  // affiché en ce moment.

  $('.topnav_back').on('click', function () {
    $(window.audiocontainer).off('.loader');
    window.audiocontainer.src = '';
    var $ecranActif = $('.active');

    if ($ecranActif.hasClass('pillarnav')) {
      loadProgressPiliers();
      showScreen($('.menu'), 'left');
    } else if ($ecranActif.hasClass('menu-modules')) {
      showScreen($('.pillarnav'), 'left');
    } else if ($ecranActif.hasClass('mainexo')) {
      window.exoEnCours = null;
      window.ecranEnCours = null;
      window.nbEcransDone = 0;
      loadProgressBar();
      loadModules();
      showScreen($('.menu-modules'), 'left');
    }
  });
  // -----------------------------------------------------------------------------

  $('.topnav_home').on('click', function () {
    window.audiocontainer.src = '';

    window.exoEnCours = null;
    window.ecranEnCours = null;
    window.nbEcransDone = 0;
    loadProgressPiliers();
    showScreen($('.menu'), 'left');
  });

  // -----------------------------------------------------------------------------
  //  ======================================
  //  ||             MAIN MENU            ||
  //  ======================================

  // On identifie le pilier sélectionné pour changer le thème de couleurs et charger
  // les entrées correspondantes au pilier.

  $('.pilier').on('click', function () {
    var $this = $(this),
      pilier = $this.attr('id');

    if ($this.hasClass('locked')) {
      return;
    }

    window.index_pilier = $this.index();
    window.objPilier = data.piliers[window.index_pilier];
    window.progPilier = progress.piliers[window.objPilier.identifiant];

    $('html').removeClass();
    $('html').addClass(pilier);
    loadEntrees();
    loadProgressEntrees();
    showPilier(pilier);
    showScreen($('.menu-entrees'));
  });

  // -----------------------------------------------------------------------------
  //  ======================================
  //  ||              PILIERS             ||
  //  ======================================

  $('.module').on('click', function () {
    var $this = $(this);

    if ($this.hasClass('locked')) {
      return;
    }

    window.index_entree = $this.index();
    window.objEntree = window.objPilier.entrees[window.index_entree];
    window.progEntree =
      progress.piliers[window.objPilier.identifiant].entrees[
        window.objEntree.identifiant
      ];

    loadModules();

    var $titreEntree = $('.titre-entree');
    $titreEntree.html(window.objEntree.titre);

    // loadProgressModules(); > passé dans loadModules()
    showScreen($('.menu-modules'));
  });

  // -----------------------------------------------------------------------------
  //  ======================================
  //  ||             MODULES              ||
  //  ======================================

  $('.exo').on('click', function () {
    var $this = $(this);

    if ($this.hasClass('locked')) {
      return;
    }

    window.nbEcransDone = 0;
    window.nbFails = 0;
    window.erreurs = 0;
    window.combo = 0;
    var $this = $(this);
    window.index_module = $this.index();
    window.objModule = window.objEntree.modules[window.index_module];
    window.progModule =
      progress.piliers[window.objPilier.identifiant].entrees[
        window.objEntree.identifiant
      ].modules[window.objModule.identifiant];
    loadExercice();
  });

  // -----------------------------------------------------------------------------
  // Va être déplacé dans la fonction LoadEcran, case 11, 12, etc
  //  ======================================
  //  ||     TEXT INPUT (Ecran 11-12)     ||
  //  ======================================

  var $formText = $('.formtext'),
    $fullscreenForm = $('.modal-input_form'),
    $fullscreenInput = $('.modal-input'),
    $textInput = $fullscreenInput.find('input');

  $formText.on('click.homescreen', function () {
    var $this = $(this).find('.field');
    if ($this.hasClass('invalidinput')) {
      $this.removeClass('invalidinput');
    }
    $this.addClass('selected');
    $fullscreenInput.removeClass('hidden');
    $textInput.css('text-transform', 'uppercase');
    $textInput.attr('autocapitalize', 'characters');
    $textInput.val($this.text()).focus();
  });

  $fullscreenForm.on('submit.homescreen', function () {
    $textInput.blur();
    return false;
  });

  $fullscreenInput.on('click.homescreen', function () {
    if ($textInput.is(event.target)) {
      return;
    }
    $textInput.blur();
  });

  $textInput.on('focusout.homescreen', function () {
    $textInput.css('tex-transform', 'none');
    $textInput.attr('autocapitalize', 'none');
    var $this = $(this);
    $formText.find('.field').filter('.selected:visible').text($this.val());
    $formText.find('.field').filter('.selected').removeClass('selected');
    $fullscreenInput.addClass('hidden');
  });

  // -----------------------------------------------------------------------------
  //  ======================================
  //  ||      SELECT LETTER (Ecran 14)    ||
  //  ======================================

  // var $lettre = $(".lettre"),
  //     $14reponse  = $(".motlettre").find("span");
  //
  // $lettre.on("click", function() {
  //   var $this = $(this);
  //   $14reponse.text($this.text());
  // });

  // -----------------------------------------------------------------------------
  //  ======================================
  //  ||        FORM FILL (Ecran 23)      ||
  //  ======================================

  // -----------------------------------------------------------------------------
  $('.active')
    .find('.help')
    .on('click', function () {
      playConsigne();
    });

  // -----------------------------------------------------------------------------
  window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    window.deferredPrompt = e;
    return false;
  });

  $('.installBtn').on('click', function () {
    promptUser();
    if (window.ua.device.type == 'Desktop') {
      if (window.ua.os.family == 'Mac OS X') {
        if (window.ua.browser.family == 'Chrome') {
        } else {
          showModalInstall(
            "Impossible d'installer l'application sur ce navigateur, merci d'utiliser Chrome"
          );
        }
      }
      if (window.ua.os.family == 'Windows') {
        showModalInstall(
          "Impossible d'installer l'application sur ce navigateur, merci d'utiliser Chrome ou Edge"
        );
      }
    }
    if (window.ua.device.type == 'Mobile') {
      if (window.ua.os.family == 'iOS') {
        if (window.ua.browser.family == 'Chrome') {
          showModalInstall(
            "Impossible d'installer l'application sur ce navigateur, merci d'utiliser Safari pour iOS"
          );
        }
        if (window.ua.browser.family == 'Safari') {
          showModalInstall(
            "Pour installer l'application, cliquez sur le bouton 'Partager', puis '+ Sur l'écran d'accueil'"
          );
        }
      }
      if (window.ua.os.family == 'Android') {
        if (window.ua.browser.family == 'Chrome') {
          if (window.ua.browser.major >= 32) {
          } else {
            showModalInstall(
              "Pour installer l'application, veuillez mettre Chrome à jour"
            );
          }
        }
        if (window.ua.browser.family == 'Firefox') {
          if (window.ua.browser.major >= 58) {
            showModalInstall(
              "Pour installer l'application, cliquez sur le bouton 'Maison +' dans la barre d'adresse, puis 'Ajouter à l'écran d'accueil'"
            );
          } else {
            showModalInstall(
              "Pour installer l'application, veuillez mettre Firefox à jour"
            );
          }
        }
        if (window.ua.browser.family == 'Opera') {
          if (window.ua.browser.major >= 32) {
            showModalInstall(
              "Pour installer l'application, cliquez sur le bouton '+' puis sélectionnez 'Ajouter à l'écran d'accueil"
            );
          } else {
            showModalInstall(
              "Pour installer l'application, veuillez mettre Opera à jour"
            );
          }
        }
      }
    }
  });

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('sw.js', { scope: './' })
      .then(function (reg) {
        // console.log('register',reg);
        // updatefound is fired if service-worker.js changes.
        reg.onupdatefound = function () {
          window.updateFound = true;
          var installingWorker = reg.installing;

          installingWorker.onstatechange = function () {
            // console.log('onstatechange',installingWorker);
            // console.log('onstatechange',installingWorker.state);
            switch (installingWorker.state) {
              case 'installing':
                break;
              case 'installed':
                window.reloadReady = true;
                break;

              case 'redundant':
                break;
              case 'active':
                break;
            }
          };
        };
        setTimeout(function () {
          if (!window.updateFound) {
            //setupScreen();
          }
        }, 3000);
      })
      .catch(function (e) {
        //Erreur pendant l'installation
      });

    navigator.serviceWorker.addEventListener('message', (event) => {
      // console.log( event );
      $('.version-cache').text(event.data.cacheName);
    });
  }
  // -----------------------------------------------------------------------------

  // FIN DU DOCUMENT.READY
});
